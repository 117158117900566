import * as React from "react";
import { useEffect, useState } from "react";
import { BaseLayout } from "../components/views/layouts/BaseLayout";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "../hooks/useWindowSize";
import { UnderContentMv } from "../components/templates/UnderContentMv";
import { BaseTableRowProps } from "../components/parts/BaseTableRow";
import { CLIENT_CONNECTING_STATE, PageSettingsType } from "../constants/api";
import { getPageSettings } from "../lib/WordPressHelper";
import { UnderContentLeadText } from "../components/parts/UnderContentLeadText";
import { TextTitle } from "../components/parts/TextTitle";
import { BaseTableTemplate } from "../components/templates/BaseTableTemplate";
import { ViewMoreButton } from "../components/parts/ViewMoreButton";
import { Loading } from "../components/svg/Loading";

const RecruitPage = () => {
  const [windowWidth, windowHeight] = useWindowSize();
  const [connectingState, setConnectingState] =
    useState<CLIENT_CONNECTING_STATE>(CLIENT_CONNECTING_STATE.IDLE);
  const [pageSettings, setPageSettings] = useState<PageSettingsType | null>(
    null
  );
  const [tableData, setTableData] = useState<BaseTableRowProps[]>([]);

  const isRecruitActive = (pageSettings: PageSettingsType | null) => {
    return pageSettings
      ? pageSettings.top_page_settings.is_recruit_active
      : false;
  };

  const recruitPageSettings = (pageSettings: PageSettingsType | null) => {
    return pageSettings ? pageSettings.recruit_page_settings : null;
  };

  const initTableData = async () => {
    const newTableData: BaseTableRowProps[] = [];

    const pageSettings = await getPageSettings();

    const recruitSettings = recruitPageSettings(pageSettings);
    // console.log(isRecruitActive(pageSettings));

    if (isRecruitActive(pageSettings) && recruitSettings) {
      if (recruitSettings.body1 && recruitSettings.body1.title) {
        newTableData.push({
          title: recruitSettings.body1.title || "",
          contents: recruitSettings.body1.contents,
        });
      }
      if (recruitSettings.body2 && recruitSettings.body2.title) {
        newTableData.push({
          title: recruitSettings.body2.title || "",
          contents: recruitSettings.body2.contents,
        });
      }
      if (recruitSettings.body3 && recruitSettings.body3.title) {
        newTableData.push({
          title: recruitSettings.body3.title || "",
          contents: recruitSettings.body3.contents,
        });
      }
      if (recruitSettings.body4 && recruitSettings.body4.title) {
        newTableData.push({
          title: recruitSettings.body4.title || "",
          contents: recruitSettings.body4.contents,
        });
      }
      if (recruitSettings.body5 && recruitSettings.body5.title) {
        newTableData.push({
          title: recruitSettings.body5.title || "",
          contents: recruitSettings.body5.contents,
        });
      }
      if (recruitSettings.body6 && recruitSettings.body6.title) {
        newTableData.push({
          title: recruitSettings.body6.title || "",
          contents: recruitSettings.body6.contents,
        });
      }
      if (recruitSettings.body7 && recruitSettings.body7.title) {
        newTableData.push({
          title: recruitSettings.body7.title || "",
          contents: recruitSettings.body7.contents,
        });
      }
      if (recruitSettings.body8 && recruitSettings.body8.title) {
        newTableData.push({
          title: recruitSettings.body8.title || "",
          contents: recruitSettings.body8.contents,
        });
      }
      if (recruitSettings.body9 && recruitSettings.body9.title) {
        newTableData.push({
          title: recruitSettings.body9.title || "",
          contents: recruitSettings.body9.contents,
        });
      }
      if (recruitSettings.body10 && recruitSettings.body10.title) {
        newTableData.push({
          title: recruitSettings.body10.title || "",
          contents: recruitSettings.body10.contents,
        });
      }
    }

    setTableData(newTableData);
  };

  const initPageSettings = async () => {
    const posts = await getPageSettings();
    setPageSettings(posts);
  };

  useEffect(() => {
    Promise.all([initPageSettings(), initTableData()]).then(() => {
      setConnectingState(CLIENT_CONNECTING_STATE.SUCCESS);
    });
  }, []);

  return (
    <BaseLayout
      pageUrl="/recruit"
      pageTitle="RECRUIT"
      pageDescription="喫茶店やカフェなどでの業務経験は問いません。本気でやりたい！という気持ちと行動が大切です。ご応募、お待ちしております！"
    >
      <UnderContentMv jaTitle="メンバー募集" enTitle="RECRUIT">
        {windowWidth < 480 ? (
          <StaticImage
            src="../images/mv/recruit.jpg"
            alt="RECRUIT メイン画像"
          />
        ) : (
          <div className="h-[420px] lg:h-[580px]">
            <StaticImage
              src="../images/mv/recruit_pc.jpg"
              alt="RECRUIT メイン画像"
              className="absolute inset-y-0 m-auto"
            />
          </div>
        )}
      </UnderContentMv>
      {connectingState !== CLIENT_CONNECTING_STATE.SUCCESS ? (
        <UnderContentLeadText mbClass="mb-10 md:mb-[60px]">
          <Loading />
        </UnderContentLeadText>
      ) : isRecruitActive(pageSettings) ? (
        <>
          <UnderContentLeadText mbClass="mb-10 md:mb-[60px]">
            <span className="text-secondary">
              “日常の中に、『小さな幸せ』を届ける”
            </span>
            <br />
            私たちはこの思いをスローガンに、日々仕事に向き合っています。
            <br />
            それは、ご来店くださるお客様、クライアント様、働くメンバーみなです。
            <br />
            そのためにも、バリスタという職業の価値と生活の向上も目指しています。
          </UnderContentLeadText>
          <UnderContentLeadText>
            <span className="text-secondary">
              喫茶店やカフェなどでの業務経験は問いません。
            </span>
            <br />
            本気でやりたい！という気持ちと行動が大切です。
            <br />
            ご応募、お待ちしております！
          </UnderContentLeadText>

          <section className="mb-[40px] md:mb-[60px]">
            <TextTitle>募集要項</TextTitle>
            <BaseTableTemplate data={tableData} isReplaceBr={true} />
          </section>
          <ViewMoreButton
            href="/contact?iqr=4"
            text="ご応募はこちら"
            isPrimary={true}
          />
          <p className="pt-[60px] text-center px-5">
            メールフォームにご記入いただいたメールアドレス宛に
            <br className="md:hidden" />
            ご連絡させていただきます。
            <br />
            気になる点などございましたら、合わせて
            <br className="md:hidden" />
            「お問合せ内容」欄にご記載ください。
            <br />
            ※Facebook、InstagramのDM、コメント等での
            <br className="md:hidden" />
            お問い合わせは返信いたしかねますので、ご遠慮下さい。
          </p>
        </>
      ) : (
        <UnderContentLeadText mbClass="mb-10 md:mb-[60px]">
          現在、スタッフの募集は行っておりません。
        </UnderContentLeadText>
      )}
    </BaseLayout>
  );
};

export default RecruitPage;
